import DownloadFile from '../download-ifc';
export let download : DownloadFile = {
    title: "Docker Container",
    description: "Can be started as Server and accessed via Browser, locally also. Java JDK 21 is required. \"mhus/kt2l-server:snapshot-2024-09-19\"",
    href: "",
    href_help: "/docs/installation/container",
    size: "",
    enabled: true,
    created: "2024-09-19"
}
